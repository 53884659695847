import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Modal,
} from "react-native";
import { FontAwesome, AntDesign } from "@expo/vector-icons";
import colors from "../../config/colors";
import {
  fetchRequestsList,
  deleteRequestTransferGroup,
  insertNewRequest,
  fetchNextPlannedTransfers,
  fetchBathtubs,
} from "../../axios/axiosConfig";
import AsyncStorage from "@react-native-async-storage/async-storage";
import NewRequestCard from "../../components/Shadows/Farmer/Movements/Elements/NewRequestCard";
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import fonts from "../../config/fonts";

const TruckTripsScreen = ({ getTranslation }) => {
  const navigation = useNavigation();
  const [requests, setRequests] = useState([]);
  const [plannedTransfers, setPlannedTransfers] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState(colors.green);
  const [bathtubs, setBathtubs] = useState(null);
  const [selectedBathtub, setSelectedBathtub] = useState(null);
  const [isFromStorage, setIsFromStorage] = useState(false);
  const route = useRoute();

  useEffect(() => {
    const loadData = async () => {
      const userData = await AsyncStorage.getItem("userData");
      const userDataObject = JSON.parse(userData);
      if (
        userDataObject &&
        (userDataObject.landingView === "SharedDepositManager" ||
          userDataObject.landingView === "Farmer")
      ) {
        await fetchTubs(userDataObject.landingView);
        await fetchListRequests();
        await fetchPlannedTransfers();
        setEditMode(false);
      }
    };

    loadData();
  }, [reload]);

  useFocusEffect(
    useCallback(() => {
      const refreshData = async () => {
        const userData = await AsyncStorage.getItem("userData");
        const userDataObject = JSON.parse(userData);
        if (
          userDataObject &&
          (userDataObject.landingView === "SharedDepositManager" ||
            userDataObject.landingView === "Farmer")
        ) {
          await fetchTubs(userDataObject.landingView);
        }
      };

      refreshData();
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      const from = route.params?.from;
      if (from) {
        setModalVisible(true);
        navigation.setParams({ from: undefined });
      }
      return () => {};
    }, [route.params])
  );

  async function fetchListRequests() {
    try {
      const response = await fetchRequestsList();
      if (response.status === 200) {
        setRequests(response.data);
      }
    } catch (error) {
      console.error("Error fetching requests:", error);
    }
  }

  async function fetchTubs(role) {
    try {
      const response = await fetchBathtubs(role);
      if (response.status === 200) {
        const bathtubsData = response.data;
        setBathtubs(bathtubsData);
        setSelectedBathtub(bathtubsData[0]?.id);
      }
    } catch (error) {
      console.error("Error fetching bathtubs:", error);
    }
  }

  async function fetchPlannedTransfers() {
    try {
      const response = await fetchNextPlannedTransfers();
      if (response.status === 200) {
        setPlannedTransfers(response.data);
      }
    } catch (error) {
      console.error("Error fetching requests:", error);
    }
  }

  const handleClosingModal = () => {
    setModalVisible(false);
  };

  const handleAddNewTrip = () => {
    setEditMode(false);
    setSelectedTrip(null);
    setModalVisible(true);
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  async function handleDelete(id) {
    const response = await deleteRequestTransferGroup(id);
    if (response.status === 200) {
      setSnackbarMessage(getTranslation("Tratta eliminata con successo"));
      setSnackbarColor(colors.green);
      setSnackbarVisible(true);
      setReload((prev) => !prev);
      hideSnackbarAfterDelay();
    } else {
      setSnackbarMessage(getTranslation(response.response.data.error));
      setSnackbarColor(colors.red);
      setSnackbarVisible(true);
      setReload((prev) => !prev);
      hideSnackbarAfterDelay();
    }
  }

  const handleModify = (trip) => {
    setEditMode(true);
    setSelectedTrip(trip);
    setModalVisible(true);
  };

  const handleFormSubmit = async (requestData) => {
    try {
      const response = await insertNewRequest(requestData);
      if (response.status === 200) {
        setSnackbarMessage(getTranslation("Richiesta inviata con successo"));
        setSnackbarColor(colors.green);
        setSnackbarVisible(true);
        setReload((prev) => !prev);
        handleClosingModal();
      } else {
        setSnackbarMessage(getTranslation(response.data.error));
        setSnackbarColor(colors.red);
        setSnackbarVisible(true);
      }
    } catch (error) {
      console.error("Error submitting request:", error);
      setSnackbarMessage(
        getTranslation("Errore durante l'invio della richiesta")
      );
      setSnackbarColor(colors.red);
      setSnackbarVisible(true);
    } finally {
      hideSnackbarAfterDelay();
    }
  };

  const hideSnackbarAfterDelay = () => {
    setTimeout(() => {
      setSnackbarVisible(false);
    }, 2000);
  };

  const renderTripItem = ({ item }, isMyTrips) => (
    <ScrollView>
      <View style={[styles.tripItem, isMyTrips && styles.myTripItem]}>
        {isMyTrips && item.status == "New" ? (
          <TouchableOpacity
            onPress={() => handleDelete(item.id)}
            style={styles.actionButton}
          >
            <FontAwesome name="trash" size={24} color="red" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => handleDelete(item.to.id)}
            style={styles.actionButton}
            disabled={true}
          ></TouchableOpacity>
        )}
        {isMyTrips ? (
          <View style={styles.tripDetails}>
            <Text style={styles.destinationText}>{item.depositName}</Text>
            <Text style={styles.materialText}>
              {getTranslation(item.contentType)}
            </Text>
          </View>
        ) : (
          <View style={styles.tripDetails}>
            <Text style={styles.destinationText}>{item.to.depositName}</Text>
            <Text style={styles.materialText}>
              {getTranslation(item.contentType)}
            </Text>
          </View>
        )}

        {isMyTrips && (
          <View style={styles.tripCountBadge}>
            <Text style={styles.tripCountText}>{item.transferNumber}</Text>
          </View>
        )}
        {isMyTrips && item.status == "New" && (
          <TouchableOpacity
            onPress={() => handleModify(item)}
            style={styles.actionButton}
          >
            <AntDesign name="edit" size={24} color={colors.violet} />
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  );

  return (
    <ScrollView style={styles.container}>
      <View style={styles.sectionContainer}>
        <View style={styles.section1}>
          <Text style={styles.sectionTitle}>
            {getTranslation("Trasporti Programmati")}
          </Text>
          {plannedTransfers.length > 0 ? (
            <FlatList
              data={plannedTransfers}
              keyExtractor={(item) => item.id}
              renderItem={(item) => renderTripItem(item, false)}
              style={styles.list}
              scrollEnabled={true}
            />
          ) : (
            <Text style={styles.emptyMessage}>
              {getTranslation("Nessuna tratta presente")}
            </Text>
          )}
        </View>
      </View>

      <View style={styles.sectionContainer}>
        <View style={styles.section2}>
          <View style={styles.myTripsHeader}>
            <Text style={styles.sectionTitle}>
              {getTranslation("Le mie richieste")}
            </Text>
            <View style={{ backgroundColor: colors.green, borderRadius: 15 }}>
              <TouchableOpacity
                style={styles.addButton}
                onPress={handleAddNewTrip}
              >
                <AntDesign name="plus" size={24} color={colors.white} />
              </TouchableOpacity>
            </View>
          </View>
          {requests.length > 0 ? (
            <FlatList
              data={requests}
              keyExtractor={(item) => item.id}
              renderItem={(item) => renderTripItem(item, true)}
              style={styles.list}
              scrollEnabled={false}
            />
          ) : (
            <Text style={styles.emptyMessage}>
              {getTranslation("Nessuna richiesta inserita")}
            </Text>
          )}
        </View>
      </View>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalView}>
          <NewRequestCard
            collapsed={true}
            getTranslation={getTranslation}
            colors={colors}
            handleClosingModal={handleClosingModal}
            onSubmit={handleFormSubmit}
            editMode={editMode}
            selectedTrip={selectedTrip}
            bathtubs={bathtubs}
            selectedBathtub={selectedBathtub}
            setSelectedBathtub={setSelectedBathtub}
            handleReload={handleReload}
            isFromStorage={isFromStorage}
          />
        </View>
      </Modal>

      {/* Snackbar Component */}
      {snackbarVisible && (
        <View
          style={[
            styles.snackbar,
            { backgroundColor: snackbarColor, borderColor: snackbarColor },
          ]}
        >
          <Text style={styles.snackbarText}>{snackbarMessage}</Text>
          <TouchableOpacity onPress={() => setSnackbarVisible(false)}>
            <Text style={styles.snackbarButton}>OK</Text>
          </TouchableOpacity>
        </View>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.green,
  },
  sectionContainer: {
    marginBottom: 20,
    paddingHorizontal: 15,
  },
  section1: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  section2: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: "bold",
    flex: 1,
    color: colors.green,
    fontFamily: fonts.regular,
  },
  list: {
    marginBottom: 15,
  },
  emptyMessage: {
    fontSize: 16,
    textAlign: "center",
    color: colors.darkGray,
    marginVertical: 20,
    fontWeight: "bold",
    fontFamily: fonts.regular,
  },
  tripItem: {
    backgroundColor: colors.lightGray,
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
    shadowColor: colors.lightGray,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 8,
    fontFamily: fonts.regular,
  },
  tripDetails: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 10,
  },
  destinationText: {
    fontSize: 18,
    fontWeight: "bold",
    color: colors.darkGray,
    fontFamily: fonts.regular,
  },
  materialText: {
    fontSize: 16,
    color: colors.gray,
    fontFamily: fonts.regular,
  },
  tripCountBadge: {
    backgroundColor: colors.green,
    borderRadius: 12,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  tripCountText: {
    fontSize: 14,
    color: colors.white,
    fontFamily: fonts.regular,
  },
  actionButton: {
    marginLeft: 10,
  },
  addButton: {
    backgroundColor: colors.lightGreen,
    padding: 10,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  myTripsHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  modalView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  snackbar: {
    position: "absolute",
    bottom: 20,
    left: 20,
    right: 20,
    padding: 10,
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  snackbarText: {
    color: colors.white,
    fontSize: 16,
    fontFamily: fonts.regular,
  },
  snackbarButton: {
    color: colors.white,
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default TruckTripsScreen;
