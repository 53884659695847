import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import colors from "../../../../config/colors";
import RangeDatePicker from "../Movements/Elements/RangeDataPicker";
import ComboPicker from "../Movements/Elements/ComboPicker";
import styles from "../../../../styles/Farmer/DataRangeStyle";
import { formatDate } from "../../../../utils/utils";
import { fetchHandleMaterials } from "../../../../axios/axiosConfig";
const DataRange = ({
  onDateRangeChange,
  startDate: initialStartDate,
  endDate: initialEndDate,
  getTranslation,
  language,
  onChangeFilters,
  bathtubs,
  selectedBathtub,
  setSelectedBathtub,
}) => {
  const [dateRangeSelected, setDateRangeSelected] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState("IN");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [materials, setMaterials] = useState([]);

  const initialMaterials = [
    { id: "Liquame", name: getTranslation("Liquame") },
    { id: "Letame", name: getTranslation("Letame") },
    { id: "DigestatoLiquido", name: getTranslation("Digestato liquido") },
    { id: "DigestatoSecco", name: getTranslation("Digestato separato") },
  ];

  useEffect(() => {
    const fetchMaterials = async () => {
      const response = await fetchHandleMaterials();
      if (response.status === 200) {
        const fetchedIds = response.data;
        const filteredMaterials = initialMaterials.filter((material) =>
          fetchedIds.includes(material.id)
        );
        /* temporary removed 
        const digestatoSecco = initialMaterials.find(
          (material) => material.id === "DigestatoSecco"
        );
        if (
          digestatoSecco &&
          !filteredMaterials.find(
            (material) => material.id === "DigestatoSecco"
          )
        ) {
          filteredMaterials.push(digestatoSecco);
        }*/
        setMaterials(filteredMaterials);
      }
    };

    fetchMaterials();
  }, []);

  const others = [
    { id: "IN", name: getTranslation("Entrato") },
    { id: "OUT", name: getTranslation("Uscito") },
  ];

  const handleDateRangeChange = (startDate, endDate) => {
    setStartDate(formatDate(startDate));
    setEndDate(formatDate(endDate));
    handleFilterChange("material", "Letame");
    onDateRangeChange(startDate, endDate);
    setDateRangeSelected(true);
    if (selectedMaterial == null) {
      setSelectedMaterial("Letame");
    }
    handleSubfilters(
      true,
      selectedMaterial,
      selectedBathtub,
      selectedDirection
    );
  };

  const handleFilterChange = (filterName, value) => {
    if (filterName === "material") {
      setSelectedMaterial(value);
    } else if (filterName === "bathtub") {
      setSelectedBathtub(value);
    } else if (filterName === "direction") {
      setSelectedDirection(value);
    }

    handleSubfilters(
      dateRangeSelected,
      filterName === "material" ? value : selectedMaterial,
      filterName === "bathtub" ? value : selectedBathtub,
      filterName === "direction" ? value : selectedDirection
    );
  };

  const handleSubfilters = (
    dateRangeSelected,
    material,
    bathtub,
    direction
  ) => {
    if (dateRangeSelected) {
      onChangeFilters({ material, bathtub, direction });
    }
  };

  return (
    <>
      <View
        style={[
          styles.content,
          { backgroundColor: colors.white, borderRadius: 16, marginTop: 10 },
        ]}
      >
        <View style={styles.pickerContainer}>
          <Text style={styles.label}> {getTranslation("Periodo")}</Text>
          <RangeDatePicker
            onDateRangeChange={handleDateRangeChange}
            getTranslation={getTranslation}
            language={language}
            dateRangeSelected={dateRangeSelected}
            formatDate={formatDate}
          />
        </View>

        {dateRangeSelected && (
          <View style={styles.pickerContainer}>
            <Text style={styles.label}> {getTranslation("Materiale")}</Text>
            <ComboPicker
              items={materials}
              filters={true}
              plates={false}
              onValueChange={(value) => handleFilterChange("material", value)}
            />
          </View>
        )}

        {selectedMaterial && selectedMaterial === "DigestatoLiquido" && (
          <View style={styles.pickerContainer}>
            <Text style={styles.label}> {getTranslation("Vasca")}</Text>
            <ComboPicker
              items={bathtubs}
              filters={true}
              plates={false}
              onValueChange={(value) => handleFilterChange("bathtub", value)}
            />
          </View>
        )}

        {selectedMaterial && selectedMaterial === "DigestatoLiquido" && (
          <View style={styles.pickerContainer}>
            <Text style={styles.label}> {getTranslation("Tipo")}</Text>
            <ComboPicker
              items={others}
              filters={true}
              plates={false}
              onValueChange={(value) => handleFilterChange("direction", value)}
            />
          </View>
        )}
      </View>
    </>
  );
};

export default DataRange;
